<template #default>
  <div class="container-fluid p-0 m-0">
    <div class="row m-0 p-0  justifyCenter">
      <div class="col-10 m-0 p-0 text-Rot">
        <h2>2024</h2>
      </div>
    </div>

    <div class="row m-0 p-0  justifyCenter">
      <div class="col-6 col-md-3 m-0 p-0 text-center">
        <p>Ausgabe 1</p>
        <img
          src="../../assets/Mitteilungsblatt/Titelbild/2024-1.jpg"
          class="Mitteilungsblattfoto zoom"
          alt="..."
        />
        <ul class="noDeko p-0">
          <li
            class="borderRund"
           @click="openPDF('IHV/IHVHeft12024.pdf')"
          >
            <span v-if="sprachID === 0" class="bold">Inhaltsverzeichnis</span>
            <span v-if="sprachID === 1" class="bold">Table of Contents</span>
          </li>
          <li
            class="borderRund"
             @click="openPDF('Probeartikel/Probeartikel_1_2024.pdf')"
          >            
            <span v-if="sprachID === 0" class="bold">Beispielartikel</span>
            <span v-if="sprachID === 1" class="bold">Example article</span>
          </li>
        </ul>
      </div>
      <div class="col-6 col-md-3 m-0 p-0 text-center">
        <p>Ausgabe 2</p>
        <img
          src="../../assets/Mitteilungsblatt/Titelbild/2024-2.jpg"
          class="Mitteilungsblattfoto zoom"
          alt="..."
        />
        <ul class="noDeko p-0">
          <li
            class="borderRund"
           @click="openPDF('IHV/IHVHeft22024.pdf')"
          >
            <span v-if="sprachID === 0" class="bold">Inhaltsverzeichnis</span>
            <span v-if="sprachID === 1" class="bold">Table of Contents</span>
          </li>
          <li
            class="borderRund"
             @click="openPDF('Probeartikel/Probeartikel_2_2024.pdf')"
          >            
            <span v-if="sprachID === 0" class="bold">Beispielartikel</span>
            <span v-if="sprachID === 1" class="bold">Example article</span>
          </li>
        </ul>
      </div>
      <div class="col-6 col-md-3 m-0 p-0 text-center">
        <p>Ausgabe 3</p>
        <img
          src="../../assets/Mitteilungsblatt/Titelbild/2024-3.jpg"
          class="Mitteilungsblattfoto zoom"
          alt="..."
        />
        <ul class="noDeko p-0">
          <li
            class="borderRund"
           @click="openPDF('IHV/IHVHeft32024.pdf')"
          >
            <span v-if="sprachID === 0" class="bold">Inhaltsverzeichnis</span>
            <span v-if="sprachID === 1" class="bold">Table of Contents</span>
          </li>
          <li
            class="borderRund"
             @click="openPDF('Probeartikel/Probeartikel_3_2024.pdf')"
          >            
            <span v-if="sprachID === 0" class="bold">Beispielartikel</span>
            <span v-if="sprachID === 1" class="bold">Example article</span>
          </li>
        </ul>
      </div>

    <div class="col-6 col-md-3 m-0 p-0 text-center">
        <p>Ausgabe 4</p>
        <img
          src="../../assets/Mitteilungsblatt/Titelbild/2024-4.jpg"
          class="Mitteilungsblattfoto zoom"
          alt="..."
        />
        <ul class="noDeko p-0">
          <li
            class="borderRund"
           @click="openPDF('IHV/IHVHeft42024.pdf')"
          >
            <span v-if="sprachID === 0" class="bold">Inhaltsverzeichnis</span>
            <span v-if="sprachID === 1" class="bold">Table of Contents</span>
          </li>
          <li
            class="borderRund"
             @click="openPDF('Probeartikel/Probeartikel_4_2024.pdf')"
          >            
            <span v-if="sprachID === 0" class="bold">Beispielartikel</span>
            <span v-if="sprachID === 1" class="bold">Example article</span>
          </li>
        </ul>
      </div> 
  <!--  </div>
       <div class="row m-0 p-0 justifyCenter">
      <div class="col-4 m-0 p-0 text-center">
        <ul class="noDeko">
          <li class="borderRund" @click="openPDF('IHV/IHV2024.pdf')">
                        <span v-if="sprachID === 0" class="bold">Jahresinhaltsverzeichnis</span>            
            <span v-if="sprachID === 1" class="bold">Annual table of contents</span>
          </li>          
        </ul>
      </div>
      <div class="col-4 m-0 p-0 text-center">
        <ul class="noDeko">
          <li class="borderRund" @click="openPDF('IHV/JIV2024-Pflanzennamen.pdf')">
            <span v-if="sprachID === 0" class="bold">Pflanzenverzeichnis</span>
            <span v-if="sprachID === 1" class="bold">Plant Directory</span>
          </li>          
        </ul>
      </div>
    </div> -->
    </div>
  </div>
</template>
<script>

import { openModal } from "jenesius-vue-modal";
import modalPDF from "../../components/ModalPDF.vue";

export default {
  name: "J2022",
  methods: {
    openPDF(pdf) {
      const props = { pdf: pdf };
      openModal(modalPDF, props);
    },
  },
    computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
}



</script>